<script lang="ts" setup>
import type { OfferMedium } from '~/types/NewTypes/CatalogNew';

const props = defineProps<{ offer: OfferMedium }>();

const { sendOfferEventToDataLayer } = useGtmModule();

function handleFavoriteStateChange(state: boolean) {
  sendOfferEventToDataLayer(state ? 'add_to_wishlist' : 'remove_from_wishlist', props.offer);
}
</script>

<template>
  <CardBlock class="product-card">
    <div class="relative">
      <AppLink :to="offer.uri">
        <ProductCardImage
          :alt="offer.product_name"
          :images="offer.offer_images"
        />
      </AppLink>

      <div class="product-card__badges">
        <div
          v-for="badge in offer.marketing_badges"
          :key="badge.slug"
          :style="{ 'border-color': badge.border_color }"
          class="product-card__badges-item"
        >
          {{ badge.title }}
        </div>
      </div>

      <FavoriteBtn
        :offer-id="offer.id"
        class="product-card__favorite-btn"
        @change-favorite-state="handleFavoriteStateChange"
      />
    </div>

    <section class="product-card__info">
      <div class="flex justify-between flex-wrap text-sm">
        <AppLink :to="offer.uri">
          <AvailabilityIndicator :offer />
        </AppLink>

        <AppLink
          v-if="Number(offer.rating) > 0"
          :to="offer.uri + '#reviews'"
          class="flex items-center gap-1"
        >
          <SvgoStar
            class="text-hell-black text-xs"
            filled
          />
          {{ String(offer.rating).slice(0, 3) }}
        </AppLink>
      </div>

      <AppLink
        :to="offer.uri"
        class="flex-grow transition hover:text-hell-black-80 leading-5"
      >
        {{ offer.product_name_medium }}
      </AppLink>

      <div class="product-card__footer">
        <AppLink :to="offer.uri">
          <div class="flex flex-col gap-1">
            <div
              v-if="offer.old_price"
              class="line-through text-hell-black-60"
            >
              {{ `${offer.old_price.toLocaleString('ru-RU')} ₽` }}
            </div>

            <div class="font-bold">
              {{ `${offer.price.toLocaleString('ru-RU')} ₽` }}
            </div>
          </div>
        </AppLink>

        <ProductCardCartInteractionBlock :offer="offer" />
      </div>

      <AdminOfferAvailabilityBlock :offer="offer" />
    </section>
  </CardBlock>
</template>

<style lang="scss" scoped>
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: auto;
  border: 1px solid transparent;
  font-size: $text-sm;
  transition: border-color 0.3s;

  @include screen-sm {
    gap: 16px;
  }

  &:hover {
    border-color: $hell-black-20;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    font-size: $text-sm;

    @include screen-md {
      font-size: $text-base;
    }
  }

  &__badges {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    gap: 0.25rem;
  }

  &__badges-item {
    padding: 3px;
    border: 1px solid $hell-black-60;
    border-radius: 4px;
    background-color: $white;
    font-size: $text-2xs;
  }

  &__favorite-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    font-size: $text-sm;

    @include screen-sm {
      gap: 16px;
    }

    @include screen-md {
      font-size: $text-base;
    }
  }
}
</style>
